// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import { Parallax } from "react-scroll-parallax"
import { InView } from "react-intersection-observer"

const WhoWeAre = ({ id }) => (
  <InView triggerOnce={true} threshold={0.2}>
    {({ inView, ref, entry }) => (
      <section
        ref={ref}
        id={id}
        className={`section alt flex-center ${inView ? "active" : ""}`}
        style={{
          margin: `0 auto`,
          //   padding: `1.45rem 1.0875rem`,
        }}
      >
        {/* <Parallax className="custom-class" x={[10, 0]} tagOuter="h2"> */}
        <h2>Who we are /</h2>
        {/* </Parallax> */}
        <div className="inner">
          {/* className="h3" */}
          <p className="h4">
            We believe in an alternate definition of “agency.” As the power to
            make things happen, to produce change. Together.
          </p>
          <p className="h4">
            We are brand builders with a communications-first approach. We
            function as an in-house resource, taking a seat at your table,
            consulting on every facet of consumer facing communications -
            leveraging all platforms and formats to tell your story.
          </p>
          <p className="h4">
            ASHE works across a range of industries - design, beauty, tech,
            services, fashion, spirits and more - the common thread, a hunger to
            innovate, a desire to move your industry forward.
          </p>
        </div>
      </section>
    )}
  </InView>
)

WhoWeAre.propTypes = {
  id: PropTypes.string,
}

WhoWeAre.defaultProps = {
  id: "",
}

export default WhoWeAre

import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import Intro from "../components/intro"
import WhoWeAre from "../components/who-we-are"
import WhatWeDo from "../components/what-we-do"
import Clients from "../components/clients"
// import Inquiry from "../components/inquiry"
import TheTeam from "../components/about"
// import Instragram from "../components/instagram"
import JSONData from "../../content/index.json"

const IndexPage = props => {
  // const { pageContext } = props
  // const { pageContent, links } = pageContext
  // console.log(props.data)
  return (
    <ParallaxProvider>
      <Layout>
        <SEO title="Home" />

        <Intro />
        <WhoWeAre id="about" />
        <WhatWeDo id="services" />
        <Clients
          id="clients"
          clients={JSONData.clients}
          clientImages={props.data}
        />
        <TheTeam id="founder" />
        {/* <Inquiry id="contact" /> */}
        {/* <Instragram id="instagram" /> */}
      </Layout>
    </ParallaxProvider>
  )
}
export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 960) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    july: file(relativePath: { eq: "july-2.jpg" }) {
      ...fluidImage
    }
    jaxonLane: file(relativePath: { eq: "jaxon-lane-2.jpg" }) {
      ...fluidImage
    }
    bythenamesake: file(relativePath: { eq: "bythenamesake-2.jpg" }) {
      ...fluidImage
    }
    floravere: file(relativePath: { eq: "floravere-5.jpg" }) {
      ...fluidImage
    }
    nyon: file(relativePath: { eq: "nyon.png" }) {
      ...fluidImage
    }
    sahroo: file(relativePath: { eq: "sahroo.jpg" }) {
      ...fluidImage
    }
    usonia: file(relativePath: { eq: "usonia-2.jpg" }) {
      ...fluidImage
    }
    skinary: file(relativePath: { eq: "skinary-2.jpg" }) {
      ...fluidImage
    }
    amandinesolbotanicals: file(
      relativePath: { eq: "amandinesolbotanicals2.jpg" }
    ) {
      ...fluidImage
    }
    colorco: file(
      relativePath: { eq: "color-co-2.jpg" }
    ) {
      ...fluidImage
    }
    aguamagica: file(
      relativePath: { eq: "agua-magica-3.png" }
    ) {
      ...fluidImage
    }
    clove: file(relativePath: { eq: "clove-3.jpg" }) {
      ...fluidImage
    }
  }
`

// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import { Parallax } from "react-scroll-parallax"
// import Masonry from "react-masonry-css"
import Image from "./image"
import { InView } from "react-intersection-observer"

import Masonry from "react-masonry-component"

const masonryOptions = {
  transitionDuration: 0,
  percentPosition: true,
  columnWidth: ".grid-sizer",
  horizontalOrder: true,
  // do not use .grid-sizer in layout
  // itemSelector: ".grid-item",
}

const Clients = ({ id, clients, clientImages }) => {
  return (
    <InView triggerOnce={true} threshold={0}>
      {({ inView, ref, entry }) => (
        <section
          ref={ref}
          className={`section flex-center alt ${inView ? "active" : ""}`}
          id={id}
          style={{
            margin: `0 auto`,
          }}
        >
          {/* <Parallax className="custom-class" x={[10, 0]} tagOuter="h2"> */}
          <h2>Clients /</h2>
          {/* </Parallax> */}
          <div className=" inner">
            <Masonry
              className={"my-gallery-class"} // default ''
              elementType={"ul"} // default 'div'
              options={masonryOptions} // default {}
              updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            >
              <div className="grid-sizer"></div>
              {clients.map((client, i) => (
                <div
                  className={
                    client.double ? "grid-item grid-item--double" : "grid-item"
                  }
                  key={client.name}
                >
                  <a href={client.url} target="_blank" rel="noreferrer">
                    <Image src={clientImages[client.src] || null} />
                    <div className="grid-item__content">{client.content}</div>
                  </a>
                </div>
              ))}
            </Masonry>
          </div>
        </section>
      )}
    </InView>
  )
}

Clients.propTypes = {
  clients: PropTypes.array,
  clientImages: PropTypes.object,
  id: PropTypes.string,
}

Clients.defaultProps = {
  clients: [],
  id: "",
}

export default Clients

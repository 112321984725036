import PropTypes from "prop-types"
import React from "react"
// import { Parallax } from "react-scroll-parallax"
import { InView } from "react-intersection-observer"

const WhatWeDo = ({ id }) => (
  <InView triggerOnce={true} threshold={0.2}>
    {({ inView, ref, entry }) => (
      <section
        ref={ref}
        id={id}
        className={`section flex-center ${inView ? "active" : ""}`}
        style={{
          margin: `0 auto`,
        }}
      >
        {/* <Parallax className="custom-class" x={[10, 0]} tagOuter="h2"> */}
        <h2>What we do /</h2>
        {/* </Parallax> */}
        <div className="inner">
          <ul className="h3">
            {/* <li>Brand Development.</li> */}
            <li>Public Relations.</li>
            <li>Affiliate Marketing.</li>
            <li>Celebrity &amp; Influencer Marketing.</li>
            <li>Partnerships &amp; Collaborations.</li>
          </ul>
        </div>
      </section>
    )}
  </InView>
)

WhatWeDo.propTypes = {
  id: PropTypes.string,
}

WhatWeDo.defaultProps = {
  id: "",
}

export default WhatWeDo

import PropTypes from "prop-types"
import React from "react"

import { Parallax } from "react-scroll-parallax"

const Intro = ({ siteTitle }) => (
  <section
    className="section section-intro flex-center"
    style={{
      margin: `0 auto`,
      maxWidth: 960,
    }}
  >
    <div className="logo">
      <Parallax y={[20, -20]}>
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="3340.000000pt"
          height="1155.000000pt"
          viewBox="0 0 3340.000000 1155.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,1155.000000) scale(0.100000,-0.100000)"
            fill="currentColor"
            stroke="none"
          >
            <path
              d="M4720 11498 c-12 -29 -1078 -2627 -2368 -5773 l-2347 -5720 189 -3
c119 -1 192 1 196 8 3 5 372 975 820 2155 l813 2145 2218 0 c1764 0 2218 -3
2222 -12 213 -524 1145 -2822 1395 -3440 l347 -858 798 0 c438 0 797 3 797 8
0 4 -1093 2602 -2429 5772 l-2429 5765 -100 3 -100 3 -22 -53z m615 -4300
c585 -1459 1062 -2654 1061 -2655 -1 -2 -952 -2 -2112 -1 l-2110 3 1038 2650
c570 1458 1040 2657 1044 2665 5 11 8 12 11 2 3 -6 483 -1206 1068 -2664z"
            />
            <path
              d="M13463 11540 c-708 -60 -1293 -333 -1778 -831 -398 -409 -613 -875
-696 -1509 -61 -472 -21 -880 132 -1345 56 -170 191 -451 293 -613 141 -222
337 -456 527 -629 344 -313 1112 -818 2164 -1423 825 -475 1320 -821 1689
-1180 226 -221 343 -377 456 -611 180 -374 234 -796 164 -1268 -80 -539 -377
-1030 -857 -1415 -532 -427 -1233 -589 -1992 -461 -1072 181 -1749 628 -2538
1678 -77 103 -144 186 -148 185 -13 -5 -159 -173 -159 -183 0 -5 35 -58 78
-117 394 -543 996 -1041 1671 -1382 477 -241 892 -369 1331 -411 646 -63 1246
5 1763 197 169 63 445 205 600 308 317 210 607 498 812 805 285 427 444 904
507 1515 19 184 16 604 -6 765 -48 360 -135 643 -287 946 -197 390 -455 696
-825 975 -438 332 -987 685 -1805 1162 -424 247 -738 442 -1040 647 -1042 706
-1469 1223 -1581 1910 -19 119 -16 446 6 565 80 443 286 805 635 1117 260 233
546 356 929 402 204 25 584 9 862 -35 591 -93 1134 -395 1557 -862 81 -90 234
-276 314 -382 l26 -34 89 73 c49 41 90 79 92 85 4 18 -258 325 -400 468 -233
233 -425 379 -646 492 -397 201 -819 328 -1267 381 -156 19 -531 27 -672 15z"
            />
            <path
              d="M18540 5775 l0 -5775 725 0 725 0 2 2988 3 2987 3183 3 3182 2 0
-2990 0 -2990 3505 0 3505 0 0 100 0 100 -2555 0 -2555 0 0 2890 0 2890 1685
0 1685 0 0 100 0 100 -1685 0 -1685 0 0 2595 0 2595 2570 0 2570 0 0 90 0 90
-3520 0 -3520 0 0 -2685 0 -2685 -3185 0 -3185 0 0 2685 0 2685 -725 0 -725 0
0 -5775z"
            />
          </g>
        </svg>
      </Parallax>
      {/* //   <Parallax y={[-100, 200]}> */}
      <p>We believe in an alternate definition of agency.</p>
      {/* //   </Parallax>{" "} */}
    </div>
  </section>
)

Intro.propTypes = {
  siteTitle: PropTypes.string,
}

Intro.defaultProps = {
  siteTitle: ``,
}

export default Intro

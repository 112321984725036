import React from "react"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const Image = ({ src }) => {
  //   const data = useStaticQuery(graphql`
  //     query {
  //       placeholderImage: file(relativePath: { eq: "ashe-logo.png" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 960) {
  //             ...GatsbyImageSharpFluid
  //           }
  //         }
  //       }
  //     }
  //   `)
  // console.log({src})

  if (!src?.childImageSharp?.fluid && !src?.childImageSharp?.fixed) {
    return <div>Picture not found</div>
  }
  if (src?.childImageSharp?.fixed) {
    return <Img fluid={src.childImageSharp.fixed} />
  }
  return <Img fluid={src.childImageSharp.fluid} />
}

export default Image

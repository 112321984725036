import PropTypes from "prop-types"
import React from "react"

import { InView } from "react-intersection-observer"
import Image from "./image"
import { useStaticQuery, graphql } from "gatsby"

const WhatWeDo = props => {
  const data = useStaticQuery(pageQuery)
  return (
    <InView triggerOnce={true} threshold={0.2}>
      {({ inView, ref, entry }) => (
        <section
          ref={ref}
          id={props.id}
          className={`section flex-center ${inView ? "active" : ""}`}
          style={{
            margin: `0 auto`,
          }}
        >
          <h2>Founder /</h2>
          <div className="inner">
            <div className="about__cover">
              <Image src={data.virginia} />
            </div>
            <p className="h4 alt">Founded By /</p>
            <p className="">
              Virginia Ross is the founder of ASHE -- a team of brand builders
              with a communications-first approach who believe in an alternate
              definition of "agency." Prior to starting ASHE in 2018, she
              pioneered digital marketing and press strategies for powerhouse
              brands and entertainment agencies such as ACE (San Pellegrino,
              Perrier, Starwood/Marriott), NET-A-PORTER, Dior and CAA.
            </p>
            {/* 
            <ul className="h4 talent-list">
              <li>
                <span className="talent-list__image">
                  <Image src={data.virginia} />
                </span>
                <span className="talent-list__text">
                  <span>Virginia Ross</span>
                  <span>Founder and CEO</span>
                </span>
              </li>
              <li>
                <span className="talent-list__image">
                  <Image src={data.chelsea} />
                </span>
                <span className="talent-list__text">
                  <span>Chelsea Simunovich</span><span> Account Director</span>
                </span>
              </li>
              <li>
                <span className="talent-list__image">
                  <Image src={data.teressa} />
                </span>
                <span className="talent-list__text">
                  <span>Teressa Farnkopf</span><span>Account Executive</span>
                </span>
              </li>
            </ul> */}
          </div>
        </section>
      )}
    </InView>
  )
}

WhatWeDo.propTypes = {
  id: PropTypes.string,
}

WhatWeDo.defaultProps = {
  id: ``,
}

export default WhatWeDo

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 960) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 200, maxHeight: 200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    virginia: file(relativePath: { eq: "VRoss_2499_bw.jpg" }) {
      ...fluidImage
    }
    virginia2: file(relativePath: { eq: "avatar-virgina-2.png" }) {
      ...squareImage
    }
    chelsea: file(relativePath: { eq: "avatar-chelsea.png" }) {
      ...squareImage
    }
    teressa: file(relativePath: { eq: "avatar-teressa-2.png" }) {
      ...squareImage
    }
  }
`
